import React, { useState } from 'react';
import { EmailCTA } from './email';
import { AddressToDistrict } from '../districts/addrtodistrict';
import drawnArrow from '../../images/misc/drawn-arrow.svg';
import { SupervisorsByName } from '../electeds/supervisors';

const fallbackPrefix = `Dear Supervisors,\n\n`;

// Get the email for every Supervisor in SupervisorsByName
const ALL_SUPES = Object.values(SupervisorsByName)
  .map((supe) => supe.email)
  .concat(['Board.of.Supervisors@sfgov.org']);
const ALL_STAFF = Object.values(SupervisorsByName).map((supe) => supe.staffEmail);

const fallbackTo = ALL_SUPES;
const fallbackCc = ALL_STAFF;

export const BaseSupervisorEmailCTA = ({
  emailCtaComponent,
  addressSelectedHandler,
  districtChangeHandler,
}) => {
  return (
    <div>
      <div className="mb-12 text-center">
        <h1 className="text-3xl sm:text-5xl !font-bold !mt-0 !mb-3">Email your Representatives!</h1>
        <h2 className="text-xl sm:text-3xl !mt-0 !font-semibold !text-gray-500">
          Make your voice heard, it matters.
        </h2>
      </div>

      <div className="grid grid-cols-5 gap-3 grid-rows-2 gap-y-8 justify-items-center">
        <div className="col-span-1">
          <div className="bg-brand-red-3 font-extrabold rounded-full h-12 w-12 flex items-center justify-center text-white">
            1
          </div>
        </div>
        <div className="col-span-4">
          <label
            htmlFor="addressFinder"
            className="font-semibold py-5 text-md sm:text-2xl text-gray-800 items-center"
          >
            (Optional) Enter your street address to find your Supervisor
          </label>
          <AddressToDistrict
            onAddressSelected={addressSelectedHandler}
            onDistrictChanged={districtChangeHandler}
          />
        </div>
        <div className="col-span-1">
          <div className="bg-brand-red-3 font-extrabold rounded-full h-12 w-12 flex items-center justify-center text-white">
            2
          </div>
        </div>
        <div className="col-span-4 w-full">
          <div className="font-semibold py-5 text-md sm:text-2xl text-gray-800 items-center pt-2">
            Send the email!
          </div>
          {emailCtaComponent}
        </div>
      </div>
    </div>
  );
};

export const SupervisorEmailCTA = ({ alwaysCc, subject, bodyWithoutGreeting, fallbackCtaText }) => {
  let [state, setState] = useState({
    districtInfo: null,
    loadingSupervisor: false,
  });

  const handleDistrictChanged = (info) => {
    setState({ ...state, loadingSupervisor: false, districtInfo: info?.supervisor });
  };

  const handleAddressSelected = () => {
    setState({ ...state, loadingSupervisor: true });
  };

  let to = fallbackTo;
  let cc = fallbackCc.concat(alwaysCc);
  let prefix = fallbackPrefix;
  let ctaText = fallbackCtaText;
  let secondaryCtaText = null;
  if (state.loadingSupervisor) {
    ctaText = 'Loading...';
    secondaryCtaText = 'Loading...';
  }
  if (state.districtInfo) {
    to = [state.districtInfo.email];
    cc = [state.districtInfo.staffEmail].concat(alwaysCc);
    prefix = `Dear Supervisor ${state.districtInfo.lastName},\n\nI'm a resident of District ${state.districtInfo.districtNum}. `;
    ctaText = `Email Supervisor ${state.districtInfo.lastName}`;
  }

  let fullBody = prefix + bodyWithoutGreeting;

  return (
    <BaseSupervisorEmailCTA
      emailCtaComponent={
        <EmailCTA
          to={to}
          cc={cc}
          body={fullBody}
          subject={subject}
          ctaText={ctaText}
          secondaryCtaText={secondaryCtaText}
          disabled={state.loadingSupervisor}
        />
      }
      addressSelectedHandler={handleAddressSelected.bind(this)}
      districtChangeHandler={handleDistrictChanged.bind(this)}
    />
  );
};

// A version of the SupervisorEmailCTA targeted at a specific Supe. If a location is specified their supe is CC'd.
export const TargetedSupervisorEmailCTA = ({
  subject,
  targets,
  alwaysCc,
  bodyWithoutGreeting,
  greeting,
  ctaTextProducer,
}) => {
  let [state, setState] = useState({
    districtInfo: null,
    loadingSupervisor: false,
  });

  const handleDistrictChanged = (info) => {
    setState({ ...state, districtInfo: info?.supervisor, loadingSupervisor: false });
  };

  const handleAddressSelected = () => {
    setState({ ...state, loadingSupervisor: true });
  };

  let cc = alwaysCc || [];
  let prefix = '';
  let ctaText = ctaTextProducer(null);
  let secondaryCtaText = null;
  if (state.loadingSupervisor) {
    ctaText = 'Loading...';
    secondaryCtaText = 'Loading...';
  }
  if (state.districtInfo) {
    cc = [state.districtInfo.email, state.districtInfo.staffEmail];
    ctaText = ctaTextProducer(state.districtInfo);
    prefix = `${greeting}\n\nI'm a resident of District ${state.districtInfo.districtNum}. `;
  }

  let fullBody = prefix + bodyWithoutGreeting;

  return (
    <BaseSupervisorEmailCTA
      emailCtaComponent={
        <EmailCTA
          to={targets}
          cc={cc}
          body={fullBody}
          subject={subject}
          ctaText={ctaText}
          secondaryCtaText={secondaryCtaText}
          disabled={state.loadingSupervisor}
        />
      }
      addressSelectedHandler={handleAddressSelected.bind(this)}
      districtChangeHandler={handleDistrictChanged.bind(this)}
    />
  );
};

export const AllSupervisorWithLocationEmailCTA = ({ alwaysCc, subject, bodyWithoutGreeting }) => {
  let cc = ALL_SUPES.concat(alwaysCc);
  return (
    <SupervisorEmailCTA
      alwaysCc={cc}
      subject={subject}
      bodyWithoutGreeting={bodyWithoutGreeting}
      fallbackCtaText="Email City Officials"
    />
  );
};

export const AllSupervisorEmailCTA = ({ alwaysCc, subject, bodyWithoutGreeting }) => {
  return (
    <TargetedSupervisorEmailCTA
      alwaysCc={alwaysCc}
      targets={ALL_SUPES}
      subject={subject}
      bodyWithoutGreeting={bodyWithoutGreeting}
      greeting={'Members of the Board of Supervisors,'}
      ctaTextProducer={(d) => {
        return d
          ? `Email our city officials and Supervisor ${d.lastName}!`
          : 'Email our city officials!';
      }}
    />
  );
};
