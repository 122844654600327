import Supervisor from '../../../src/components/electeds/supervisors';

export const Ronen = new Supervisor({
  firstName: 'Hillary',
  lastName: 'Ronen',
  phone: '(415) 554-5144',
  growSfEndorsed: false,
  specialElection: null,
  elected: '11/8/2016',
  margin: 9281,
  reElected: '11/3/2020',
  reElectionMargin: null,
  appointed: null,
  nextElection: null,
  leftOffice: '1/8/2025',
  type: 'Supervisor',
  district: '9',
  reElectionMargin: null,
});

export default Ronen;
