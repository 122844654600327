import Supervisor from '../../../src/components/electeds/supervisors';

export const Safai = new Supervisor({
  firstName: 'Ahsha',
  lastName: 'Safai',
  displayName: 'Ahsha Safaí',
  phone: '(415) 554-6975',
  growSfEndorsed: false,
  specialElection: null,
  elected: '11/8/2016',
  margin: 413,
  reElected: '11/3/2020',
  reElectionMargin: 1897,
  appointed: null,
  nextElection: null,
  leftOffice: '1/8/2025',
  type: 'Supervisor',
  district: '11',
  archive: true,
});

export default Safai;
