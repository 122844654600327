import Supervisor from '../../../src/components/electeds/supervisors';

export const Preston = new Supervisor({
  firstName: 'Dean',
  lastName: 'Preston',
  phone: '(415) 554-7630',
  growSfEndorsed: false,
  specialElection: '11/5/2019',
  specialMargin: 185,
  elected: '11/3/2020',
  margin: 4333,
  reElected: null,
  appointed: null,
  nextElection: null,
  leftOffice: '1/8/2025',
  reElected: '11/5/2024',
  reElectionMargin: -1664,
  type: 'Supervisor',
  district: '5',
  archive: true,
});

export default Preston;
