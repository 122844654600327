import Supervisor from '../../../src/components/electeds/supervisors';

export const Stefani = new Supervisor({
  firstName: 'Catherine',
  lastName: 'Stefani',
  phone: '(415) 554-7752',
  growSfEndorsed: false,
  specialElection: null,
  elected: '11/6/2018',
  margin: 1279,
  reElected: '11/8/2022',
  reElectionMargin: null,
  appointed: '1/30/2018',
  nextElection: null,
  leftOffice: '12/18/2024',
  type: 'Supervisor',
  district: '2',
  archive: true,
});

export default Stefani;
