import Supervisor from '../../../src/components/electeds/supervisors';

export const Peskin = new Supervisor({
  firstName: 'Aaron',
  lastName: 'Peskin',
  phone: '(415) 554-7450',
  growSfEndorsed: false,
  specialElection: '11/3/2015',
  specialMargin: 1426,
  elected: '11/8/2016',
  margin: 11764,
  reElected: '11/3/2020',
  reElectionMargin: 3731,
  appointed: null,
  nextElection: null,
  leftOffice: '1/8/2025',
  type: 'Supervisor',
  district: '3',
  archive: true,
});

export default Peskin;
